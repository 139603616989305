const getFormApiHost = () => {
    const key = window.location.host.split('.')[0].toLocaleLowerCase();
    switch (key) {
        case 'forms-qa':
            return 'https://forms-qa.azurewebsites.net/api';
        default:
            return 'https://forms-365.azurewebsites.net/api';
    }

};
const getSiteGovernUrl = () => {

    const key = window.location.host.split('.')[0].toLocaleLowerCase();
    switch (key) {
        case 'sbdemo':
            return 'https://sitegoverndemo.schoolbundle365.com/';
        default:
            return 'https://sitegovern.sbqa01.com/';
    }
};
const formHost = getFormApiHost();
const sgUrl = getSiteGovernUrl();
const apiConfig = {
    endpoint: `${formHost}/subm/submit`,
    sgUrl: sgUrl,
    GetApiUrl: `${formHost}/form`,
    GetSubmissionUrl: `${formHost}/_debug/subm`,
    PostApiUrl: `${formHost}/ws`
};
export { apiConfig };